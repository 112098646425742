/**
 * Created by zhaoyue on 16/11/28.
 */

export const ID = '@loan-manage'

export default {
  ID: ID,
  GET_REPAYMENT_LIST: `${ID}/GET_REPAYMENT_LIST`,
  APPEND_REPAYMENT_LIST: `${ID}/APPEND_REPAYMENT_LIST`,
  PUT_APPROVE_REPAYMENT: `${ID}/PUT_APPROVE_REPAYMENT`,
  GET_CORP_LOANINFO_LIST: `${ID}/GET_CORP_LOANINFO_LIST`,
  GET_REPAYMENT_RECORD_LIST: `${ID}/GET_REPAYMENT_RECORD_LIST`,
  APPEND_REPAYMENT_RECORD_LIST: `${ID}/APPEND_REPAYMENT_RECORD_LIST`,
  GET_CORP_REMAIN: `${ID}/GET_CORP_REMAIN`,
  GET_CORP_APPROVE_BALANCE: `${ID}/GET_CORP_APPROVE_BALANCE`,
  GET_LOAN_PACKAGE_LIST_BY_ID: `${ID}/GET_LOAN_PACKAGE_LIST_BY_ID`,
  GET_BACKLOG_INFO_BY_ID: `${ID}/GET_BACKLOG_INFO_BY_ID`,
  GET_LOAN_PACKAGE_DETAIL_BY_ID: `${ID}/GET_LOAN_PACKAGE_DETAIL_BY_ID`,
  GET_REPAYMENT_LIST_COUNT: `${ID}/GET_REPAYMENT_LIST_COUNT`,
  CHECK_LOAN_PACKAGE_CLOSE_STATUS: `${ID}/CHECK_LOAN_PACKAGE_CLOSE_STATUS`,
  CLOSE_LOAN_PACKAGE: `${ID}/CLOSE_LOAN_PACKAGE`,
  REMIND_LOAN_REPAYMENT: `${ID}/REMIND_LOAN_REPAYMENT`,
  LOAN_RULE_CONFIG: `${ID}/LOAN_RULE_CONFIG`,
  PUT_LOAN_RULE_CONFIG: `${ID}/PUT_LOAN_RULE_CONFIG`,
  LOAN_RULE_CONFIG_LIST: `${ID}/LOAN_RULE_CONFIG_LIST`,
  DELETE_LOAN_RULE_CONFIG_LIST: `${ID}/DELETE_LOAN_RULE_CONFIG_LIST`,
  SAVE_RULE_CONFIG_LIST: `${ID}/SAVE_RULE_CONFIG_LIST`,
  PUT_RULE_CONFIG_LIST: `${ID}/PUT_RULE_CONFIG_LIST`,
  EDIT_RULE_CONFIG_LIST: `${ID}/EDIT_RULE_CONFIG_LIST`
}
