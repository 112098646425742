/**
 * Created by zhaoyue on 16/11/28.
 */
// import { selectEntity, joinParams } from '../../lib/entity.join'
import { app as api } from '@ekuaibao/whispered'
import { QuerySelect } from 'ekbc-query-builder'
// const { fetchAttachment, fetchInvoice } = api.require('@lib/attachment-fetch')
// import fetchMutilValue from '../../lib/mutil-staff-fetch'
// const fetchMutilValue = api.require('@lib/mutil-staff-fetch')

import key from './key'
import { Resource, Fetch } from '@ekuaibao/fetch'
const repayment = new Resource('/api/v1/loan/repayment')
const loanInfo = new Resource('/api/v1/loan/loanInfo')
const loanInfo2 = new Resource('/api/v2/loan/loanInfo')
const loanAction = new Resource('/api/v1/loan')
const flows = new Resource('/api/flow/v1/flows')
const loanConfig = new Resource('/api/v2/loan/config')
const configRule = new Resource('/api/v2/loan/configRule')
const batchShare = new Resource('/api/v1/loan/loanInfo/shareBatch')
import parseSelectUsePropertySet from '@ekuaibao/lib/lib/parseSelectUsePropertySet'
import parseQuery2Select from '@ekuaibao/lib/lib/parseQuery2Select'
// const filtersFixer = api.require('@lib/filtersFixer')
import { cloneDeep } from 'lodash'

//查询企业还款申请金额 /api/v1/loan/repayment/corp/approveBalance
//查询企业待还款金额 /api/v1/loan/loanInfo/corp/remain
function getFilterQuery(params) {
  const lang = Fetch.staffSetting ? Fetch.staffSetting.language : Fetch.defaultLanguage === 'en-US'
  let __params = cloneDeep(params)
  let loanInfoRemindStr = ''
  const { otherFilters = [] } = params
  if (params.filters && params.filters.loanInfoRemind) {
    const { loanInfoRemind = [] } = params.filters.loanInfoRemind
    if (loanInfoRemind.length) {
      const [value] = loanInfoRemind
      if (loanInfoRemind.length === 1 && !value) {
        delete __params.filters.loanInfoRemind
        loanInfoRemindStr = `loanInfoRemind==${params.filters.loanInfoRemind.loanInfoRemind} || isNull(loanInfoRemind)`
      } else if (loanInfoRemind.length === 2) {
        delete __params.filters.loanInfoRemind
      }
    }
  }
  let query = parseQuery2Select(__params, undefined, undefined, lang)
  if (loanInfoRemindStr) {
    query = query.filterBy(loanInfoRemindStr)
  }
  if (otherFilters?.length) {
    otherFilters.forEach((filter) => {
      query.filterBy(filter)
    })
  }
  return query
}
//根据状态查询该企业所有借款包列表 /api/v1/loan/loanInfo/search
export function getCorpLoanInfoList(params, scene, dimensionItems, properties, privilegeId) {
  const filtersFixer = api.require('@lib/filtersFixer')
  const sceneFiltersQuery = scene ? filtersFixer(scene, undefined, dimensionItems) : ''
  const order = { order: (params.order && params.order.order) || 'desc' }
  let query = getFilterQuery(params)
  const content = parseSelectUsePropertySet(properties)

  query.orderBy(order.field, order.order.toUpperCase()).filterBy(sceneFiltersQuery)
    .select(`ownerId(id,name,code,email,cellphone,note),
    flowId(ownerId(id,name,code,email,cellphone,note),form(${content},...),...),
    ...`)

  const queryString = query.value()
  return loanInfo.POST('/search', queryString, privilegeId ? { privilegeId } : {})
}

export function getCorpLoanInfoApproving(params, scene, dimensionItems, properties, id) {
  const lang = Fetch.staffSetting ? Fetch.staffSetting.language : Fetch.defaultLanguage === 'en-US'
  const { otherFilters } = params
  const query = parseQuery2Select(params, undefined, 'flow', lang)
  const filtersFixer = api.require('@lib/filtersFixer')
  const sceneFiltersQuery = scene ? filtersFixer(scene, 'flow', dimensionItems) : ''

  const content = parseSelectUsePropertySet(properties)

  query
    .filterBy('formType == "loan"')
    .filterBy(`state.in("approving","paying","sending","receiving","rejected","nullify")`)
    .filterBy(sceneFiltersQuery)
    .select(`ownerId(id,name,code,email,cellphone,note),form(${content},...),...`)
  if (otherFilters?.length) {
    otherFilters.forEach((filter) => {
      query.filterBy(filter)
    })
  }

  const queryString = query.value()
  return flows.POST(
    '/search',
    queryString,
    id
      ? { privilegeId: id, join: 'id,nodeState,/flow/v2/backlogs/state' }
      : { formType: 'loan', join: 'id,nodeState,/flow/v2/backlogs/state' },
  )
}

//查询企业待还款金额 /api/v1/loan/loanInfo/corp/remain
export function getCorpRemain(param, privilegeId, scene, dimensionItems) {
  const query = getFilterQuery(param)
  const filtersFixer = api.require('@lib/filtersFixer')
  const sceneFiltersQuery = scene ? filtersFixer(scene, undefined, dimensionItems) : ''
  query.filterBy(sceneFiltersQuery)
  const queryString = query.value()
  // let filter = queryString.filterBy
  // queryString.filter = filter
  // delete queryString.filterBy
  delete queryString.limit
  return {
    type: key.GET_CORP_REMAIN,
    payload: loanInfo.POST('/corp/remain', { ...queryString }, { privilegeId }),
  }
}

//返回企业特定状态的还款申请 /api/v1/loan/repayment/corp/byState
export function getRepaymentList(data, done, append = false) {
  let departments = 'departments,departmentArr,/v1/organization/departments?select=id,name'
  let params = {
    join: `ownerId,ownerObj,/v1/organization/staffs?join=${departments}`,
    join$1:
      'loanInfoId,loanInfoObj,/v1/loan/loanInfo?join=repaymentRecords.casherId,staff,/v1/organization/staffs?select=id,name&join=repaymentRecords.accountId,accountCompany,/pay/v1/accounts',
    join$2: 'attachments.fileId,fileId,/v1/attachment/attachments',
    join$3: 'accountId,accountId,/pay/v2/accounts',
    ...data,
  }
  if (append) {
    return {
      type: key.APPEND_REPAYMENT_LIST,
      payload: repayment.GET('/corp/byState', params),
      done,
    }
  }
  return {
    type: key.GET_REPAYMENT_LIST,
    payload: repayment.GET('/corp/byState', params),
    done,
  }
}

// 只是为了获得列表的数量
export function getRepaymentListCount(id) {
  let params = {
    start: 0,
    count: window.isNewHome ? 20 : 10,
    filter: 'state.in("APPROVE")',
  }
  if (id) {
    params.privilegeId = id
  }
  return {
    type: key.GET_REPAYMENT_LIST_COUNT,
    payload: repayment.GET('/corp/byState', params),
  }
}

//查询企业还款申请金额 /api/v1/loan/repayment/corp/approveBalance
export function getCorpApproveBalance(param) {
  return {
    type: key.GET_CORP_APPROVE_BALANCE,
    payload: repayment.GET('/corp/approveBalance', param),
  }
}

//查询企业的还款记录列表 /api/v1/loan/loanInfo/corp/repaymentRecord
export function getRepaymentRecordList(data, done, append = false) {
  let departments =
    'departments,departmentArr,/v1/organization/departments/withFullName?select=id,name,fullName'
  let params = {
    join: `ownerId,ownerObj,/v1/organization/staffs?join=${departments}`,
    join$1:
      'loanInfoId,loanInfoObj,/v1/loan/loanInfo?join=repaymentRecords.casherId,staff,/v1/organization/staffs?select=id,name&join=repaymentRecords.accountId,accountCompany,/pay/v1/accounts',
    join$2: `casherId,casherObj,/v1/organization/staffs`,
    join$3: 'accountId,accountCompany,/pay/v1/accounts',
    ...data,
  }
  if (append) {
    return {
      type: key.APPEND_REPAYMENT_RECORD_LIST,
      payload: loanInfo.GET('/corp/repaymentRecord', params),
      done,
    }
  }
  return {
    type: key.GET_REPAYMENT_RECORD_LIST,
    payload: loanInfo.GET('/corp/repaymentRecord', { ...params }),
    done,
  }
}

//审批还款申请
export function approveRepayment(params, privilegeId) {
  return {
    type: key.PUT_APPROVE_REPAYMENT,
    payload: api
      .invoke('@vendor:dd:message:code')
      .then((messageCode) => repayment.PUT('/approve/$id', params, { messageCode, privilegeId }))
      .catch((e) => Promise.reject(e)),
  }
}

//根据借款人ID和当前单据ID查询该借款人所有借款包列表
export function getLoanPackageListByID(params, done) {
  return {
    type: key.GET_LOAN_PACKAGE_LIST_BY_ID,
    payload: loanInfo.GET('/byOwnerIdAndLoanInfoId/$ownerId', params),
    done,
  }
}

//获取审批流程详情
export function getBacklogInfoById({ id }, privilegeId) {
  const { joinParams, selectEntity } = api.require('@lib/entity.join')
  const { fetchAttachment, fetchInvoice } = api.require('@lib/attachment-fetch')
  const fetchMutilValue = api.require('@lib/mutil-staff-fetch')
  let query = new QuerySelect().filterBy(`id=="${id}"`).select(`${selectEntity()}`).value()
  let params = {
    id,
    flowManager: true,
    privilegeId,
    ...joinParams(),
  }
  return {
    type: key.GET_BACKLOG_INFO_BY_ID,
    payload: flows
      .POST('/flowId/$id', query, params)
      .then(fetchAttachment)
      .then(fetchInvoice)
      .then(fetchMutilValue),
  }
}

export function getloanpackageDetailInfo(id, privilegeId) {
  let departments = 'departments,departmentArr,/v1/organization/departments?select=id,name'
  let params = {
    id,
    privilegeId,
    join: `repaymentRecords.casherId,staff,/v1/organization/staffs?join=${departments}`,
    join$1: `repaymentRecords.accountId,accountCompany,/pay/v1/accounts`,
    join$2: `repaymentRecords.ownerId,ownerId,/v1/organization/staffs`,
    join$3: `repaymentRecords.shiftStaffId,shiftStaffId,/v1/organization/staffs`,
    join$4: `repaymentRecords.attachments.fileId,fileId,/v1/attachment/attachments`,
    join$5: `flowSpecificationId,flowSpecificationId,/form/v1/specificationVersions`,
    join$6: `repaymentRecords.staffId,staffId,/v1/organization/staffs`,
    join$7: 'repaymentRecords.casherId,staffs,/v1/organization/staffs',
    join$8: 'repaymentRecords.shareStaffIds,shareStaffIds,/v1/organization/staffs',
    join$9: 'repaymentRecords.cancelShareStaffIds,cancelShareStaffIds,/v1/organization/staffs',
    join$10: 'repaymentRecords.payerOwnerId,payerOwnerId,/v1/organization/staffs',
    join$11: `ownerId,ownerId,/v1/organization/staffs`,
    join$12: `sharedOwnerIds,sharedOwnerIds,/v1/organization/staffs`,
    join$13: 'notConfirmOwnerIds,notConfirmOwnerIds,/v1/organization/staffs',
  }
  return {
    type: key.GET_LOAN_PACKAGE_DETAIL_BY_ID,
    payload: loanInfo.GET('/$id', params),
  }
}

export function checkIsCanRepayment(loanInfoId, privilegeId) {
  return {
    type: key.CHECK_LOAN_PACKAGE_CLOSE_STATUS,
    payload: loanAction.GET('/cashierRepayment/checkIsCanRepayment/$loanInfoId', {
      loanInfoId,
      privilegeId,
    }),
  }
}

export function closeLoanPackage(param, query) {
  return {
    type: key.CLOSE_LOAN_PACKAGE,
    payload: loanAction.POST('/cashierRepayment/cashierPayment', param, query),
  }
}

export function remindRepayment(params) {
  return {
    type: key.REMIND_LOAN_REPAYMENT,
    payload: loanInfo2.POST(`/remind`, params),
  }
}

// 获取配置信息
export function getLoanConfig() {
  return {
    type: key.LOAN_RULE_CONFIG,
    payload: loanConfig.GET(),
  }
}

// 修改配置信息
export function putLoanConfig(param) {
  return {
    type: key.PUT_LOAN_RULE_CONFIG,
    payload: loanConfig.PUT('', param),
  }
}
// 获取配置列表
export function getLoanConfigRule() {
  return {
    type: key.LOAN_RULE_CONFIG_LIST,
    payload: configRule.GET(''),
  }
}

//删除配置
export function deleteLoanConfigRule(param) {
  return {
    type: key.DELETE_LOAN_RULE_CONFIG_LIST,
    payload: configRule.DELETE('/$id', { ...param }),
  }
}

// 保存新的条件
export function saveLoanConfigRule(param) {
  return {
    type: key.SAVE_RULE_CONFIG_LIST,
    payload: configRule.POST('', param),
  }
}

// 修改条件
export function putLoanConfigRule(param) {
  return {
    type: key.PUT_RULE_CONFIG_LIST,
    payload: configRule.PUT('', param),
  }
}

// 当前编辑的条件
export function editLoanConfigRule(param) {
  return {
    type: key.EDIT_RULE_CONFIG_LIST,
    payload: param,
  }
}

// 前款逾期，后款不借条件保存接口
export function postLoanOverdueRule(param) {
  return configRule.POST('/$loanType', param)
}

// 前款逾期，后款不借条件更新接口
export function putLoanOverdueRule(param) {
  return configRule.PUT('/$loanType', param)
}

//批量共享接口
export function batchShareLoan(param) {
  return batchShare.POST('', param)
}

//批量转交接口
export function batchTransferLoan(param, query) {
  return loanInfo2.POST('/batch/shift/$staffId', param, query)
}

export function batchCloseLoanPackage(param) {
  return loanAction.POST('/cashierRepayment/cashierPaymentBatch', param)
}

// /api/v1/loan/repayment/chargeAgainst
/**
 * @description 冲销
 * @param {*} ids
 * @returns
 */
export function getChargeAgainst(ids) {
  return repayment.POST('/chargeAgainst', ids)
}
