/**
 * Created by zhaoyue on 16/11/28.
 */
import { app as api } from '@ekuaibao/whispered'
import { getRepaymentList, remindRepayment } from './loan-manage-action'

export default [
  {
    id: '@loan-manage',
    reducer: () => import('./loan-manage-reducer'),
    path: '/loan-manage',
    ref: '/',
    onload: () => import('./loan-manage-view'),
    'check:privilege'(params) {
      return api.dispatch(getRepaymentList(params))
    },
    'remind:repay:ment'(params) {
      return api.dispatch(remindRepayment(params))
    }
  },
  {
    path: '/loan-manage-privilege/:id',
    ref: '/',
    onload: () => import('./loan-manage-view')
  },
  {
    point: '@@menus',
    onload: () => [
      {
        id: 'loan-manage-privilege',
        pId: 'financial',
        href: '/loan-manage-privilege/:id',
        __hidden: true,
        __routeable: true
      }
    ]
  },
  {
    point: '@@layers',
    prefix: '@loan-manage',
    onload: () => import('./layers')
  }
]
